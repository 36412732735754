const app = angular.module('StripeServer', [
    
]);

app.directive('errorMessage', function() {
    return {
        scope: true,
        template: `
            <div class="text-danger" ng-show="messages.length">
                <div ng-repeat="message in messages">
                    {{ message }}
                </div>
            </div>
        `,
        link: function(scope, element, attributes) {
            scope.messages = {};
            if (scope.errorMessages) {
                scope.messages = scope.errorMessages[attributes.errorMessage];
                delete scope.errorMessages[attributes.errorMessage];
            }
        },
    };
});

app.directive('errorMessages', ['$parse', function($parse) {
    return {
        template: `
            <div class="text-danger" ng-repeat="(key, messages) in errorMessages">
                <div ng-repeat="message in messages">
                    <span style="display:none">{{ key }}</span>
                    {{ formatMessage(message, key) }}
                </div>
            </div>
        `,
        link: function(scope, element, attributes) {
            scope.errorMessages = $parse(attributes.errorMessages)();
            scope.formatMessage = function(message, key) {
                switch (message) {
                    case 'This field is missing.': {
                        return 'The ' + key + ' field was missing.';
                    }
                    case 'This field was not expected.': {
                        return 'The ' + key + ' field was not expected.';
                    }
                    case 'This value should not be blank.': {
                        return 'The ' + key + ' field should not be blank.';
                    }
                }
                return message;
            };
        },
    };
}]);
